/* global mod, Waypoint, google, Swiper, YT, zoom, tribeUtils */

"use strict";

$(function() {
	var navigationPrimarySticky;
	var navigationPrimary = {
		initialize: function() {
			navigationPrimarySticky = new Waypoint.Sticky({
				element: $(".site__navigation")[0],
				stuckClass: "site__navigation--fixed"
			});
		},
		destroy: function() {
			navigationPrimarySticky.destroy();
		},
		reinit: function() {
			if(typeof navigationPrimarySticky.options !== "undefined") {
				navigationPrimarySticky.destroy();
			}
			navigationPrimary.initialize();
		}
	};

	navigationPrimary.initialize();

	// Click.
	$(document).on("click", ".icon-searching-magnifying-glass, .icon-close-button, .site__search__button, .site__navigation__menu, .site__navigation__menu__close, .module__map__container__nav__left-arrow, .module__map__container__nav__right-arrow, .content__hero__filters__open, .content__hero__filters__close, .content__hero__filter--filtered, .tribe-bar-search-filter__header, .tribe-bar-search-filter__checkbox, .tribe_events_filters_mobile_nav_item, .tribe-bar-search-button, #tribe_events_filters_reset", function() {
		var $el = null;

		if($(this).hasClass("menu-item-type-custom") && $(this).hasClass("icon-searching-magnifying-glass")) {
			$(".icon-searching-magnifying-glass").addClass("icon-searching-magnifying-glass--hidden");
			$(".icon-close-button").addClass("icon-close-button--visible");
			$(".site__search").addClass("site__search--visible");
			$(".site__search__input").focus();
		} else if($(this).hasClass("icon-close-button")) {
			if (typeof mod.modules.gallery !== "undefined" && $(".module__gallery__content").is(":visible")) {
				mod.modules.gallery.overlay.hideOverlay();
			} else if($(this).hasClass("content__hero__filters__close")) {
				$("body").removeClass("body--locked");
				$(".content__hero__filters__open").addClass("content__hero__filters__open--visible");
				$(".content__hero__filters__close").removeClass("content__hero__filters__close--visible");
				$(".content__hero__filters__container").removeClass("content__hero__filters__container--visible");
			} else if($(this).hasClass("content__hero__filter--filtered")) {
				return true;
			} else {
				$(".icon-searching-magnifying-glass").removeClass("icon-searching-magnifying-glass--hidden");
				$(".icon-close-button").removeClass("icon-close-button--visible");
				$(".site__search").removeClass("site__search--visible");
			}
		} else if($(this).hasClass("site__search__button")) {
			$el = $(".site__search__input");
			if($el.val().length === 0) {
				$el.focus();
			} else {
				return true;
			}
		} else if($(this).hasClass("site__navigation__menu")) {
			$(".site__content").addClass("site__content--masked");
			$(this).addClass("site__navigation__menu--hidden");
			$(".site__navigation__menu__close").addClass("site__navigation__menu__close--visible");
			$(".site__navigation__ancillary").addClass("site__navigation__ancillary--visible");
			$(".site__navigation__helper").css("max-height", "700px");
		} else if($(this).hasClass("site__navigation__menu__close")) {
			$(".site__content").removeClass("site__content--masked");
			$(".site__navigation__menu__close").removeClass("site__navigation__menu__close--visible");
			$(".site__navigation__menu").removeClass("site__navigation__menu--hidden");
			$(".site__navigation__ancillary").removeClass("site__navigation__ancillary--visible");
			$(".site__navigation__helper").css("max-height", "50px");
		} else if($(this).hasClass("module__map__container__nav__left-arrow") || $(this).hasClass("module__map__container__nav__right-arrow")) {
			if(mod.modules.map.buttonsActive) {
				if($(this).hasClass("module__map__container__nav__left-arrow")) {
					mod.modules.map.locIterator--;
				}

				if($(this).hasClass("module__map__container__nav__right-arrow")) {
					mod.modules.map.locIterator++;
				}

				if(mod.modules.map.locIterator > mod.modules.map.locationsData.length - 1) {
					mod.modules.map.locIterator = 0;
				}

				if(mod.modules.map.locIterator < 0) {
					mod.modules.map.locIterator = mod.modules.map.locationsData.length - 1;
				}
				mod.modules.map.markerFocus();
			}
		} else if($(this).hasClass("content__hero__filters__open")) {
			$("body").addClass("body--locked");
			$(".content__hero__filters__open").removeClass("content__hero__filters__open--visible");
			$(".content__hero__filters__close").addClass("content__hero__filters__close--visible");
			$(".content__hero__filters__container").addClass("content__hero__filters__container--visible");
		} else if($(this).hasClass("tribe_events_filters_mobile_nav_item")) {
			if($(this).hasClass("tribe_events_filter_item--active")) {
				$(".tribe_events_filter_item").hide();
				$(".tribe_events_filters_mobile_nav_item").removeClass("tribe_events_filter_item--active");
				$(".tribe-events-filter-buttons").hide();
				return false;
			}
			$(".tribe_events_filter_item").hide();
			$(".tribe_events_filters_mobile_nav_item").removeClass("tribe_events_filter_item--active");
			$(this).addClass("tribe_events_filter_item--active");
			if($(this).hasClass("tribe_events_filters_mobile_nav_item_calendar")) {
				$("this").addClass("tribe_events_filter_item--active");
				$("#tribe_events_filter_item_calendar").show();
			} else if($(this).hasClass("tribe_events_filters_mobile_nav_item_search")) {
				$("this").addClass("tribe_events_filter_item--active");
				$("#tribe_events_filter_item_search").show();
			} else if($(this).hasClass("tribe_events_filters_mobile_nav_item_districts")) {
				$("#tribe_events_filter_item_eventcategory").show();
			} else if($(this).hasClass("tribe_events_filters_mobile_nav_types")) {
				$("#tribe_events_filter_item_tags").show();
			}
			$(".tribe-events-filter-buttons").show();
		} else if($(this).hasClass("tribe-bar-search-button")) {
			if(window.innerWidth <= 960) {
				$(".tribe_events_filter_item").hide();
				$(".tribe_events_filters_mobile_nav_item").removeClass("tribe_events_filter_item--active");
				$(".tribe-events-filter-buttons").hide();
			}
			return true;
		} else if(this.id === "tribe_events_filters_reset") {
			if( $(".day.active").length > 0 ) {
				window.location.href = "/calendar";
				return false;
			}
			return true;
		}
		return false;
	});

	// Focusout.
	$(document).on("focusout", ".site__search__form", function() {
		if($(".site__search__input").val().length === 0) {
			$(".menu-item-type-custom.icon-close-button").trigger("click");
		}
	});

	// Keydown.
	$(document).on("keydown", function(e) {
		if(e.which === 27 && $(".module__gallery__content").is(":visible")) {
			mod.modules.gallery.overlay.hideOverlay();
			return false;
		}
	});

	// Resize.
	$(window).on("resize", function() {
		if(window.innerWidth > 960 && $(".site__navigation__menu").hasClass("site__navigation__menu--hidden")) {
			$(".site__navigation__menu__close").trigger("click");
		}
		navigationPrimary.reinit();
		if($("#map").length > 0) {
			mod.modules.map.adjustMap();
		}

		if($("body").hasClass("post-type-archive-tribe_events") && window.innerWidth > 960) {
			$(".tribe_events_filter_item").show();
			$(".tribe-events-filter-buttons").show();
		} else if($("body").hasClass("post-type-archive-tribe_events") && window.innerWidth <= 960) {
			$(".tribe_events_filter_item").removeClass("closed").hide();
			$(".tribe_events_filters_mobile_nav_item").removeClass("tribe_events_filter_item--active");
			$(".tribe-events-filter-buttons").hide();
		}
	});

	$.fn.datepicker.dates.en = {
		days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
		daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		daysMin: ["S", "M", "T", "W", "T", "F", "S"],
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		today: "Today",
		clear: "Clear",
		format: "yyyy-mm-dd",
		titleFormat: "MM yyyy",
		weekStart: 0
	};

	/* jshint quotmark: false */
	$(".datepickerB").datepicker({
		format: "yyyy-mm-dd",
		minViewMode: "days",
		maxViewMode: "days",
		templates: {
	    leftArrow: '<span class="icon icon-keyboard-left-arrow-button"></span>',
	    rightArrow: '<span class="icon icon-keyboard-right-arrow-button"></span>'
		},
	}).on("changeDate", function() {
		$("#tribe-bar-date").val($(this).datepicker("getDate").format("yyyy-mm-dd"));
	});
	/* jshint quotmark: true */

	if($("body").hasClass("post-type-archive-tribe_events") && typeof tribeUtils.getQueryVars()["tribe-bar-date"] !== "undefined"){
		var date = tribeUtils.getQueryVars()["tribe-bar-date"].split("-");
		$(".datepickerB").datepicker("setDate", new Date(date[0], date[1] - 1, date[2]));
	}

	if($("body").hasClass("post-type-archive-tribe_events")) {
		if(localStorage.getItem("tribe_events_filter_item_eventcategory") === null && localStorage.getItem("tribe_events_filter_item_eventcategory") !== "open") {
			$("#tribe_events_filter_item_eventcategory").addClass("closed");
		} else if(localStorage.getItem("tribe_events_filter_item_eventcategory") === "open") {
			$("#tribe_events_filter_item_eventcategory").removeClass("closed");
		}

		if(localStorage.getItem("tribe_events_filter_item_tags") === null && localStorage.getItem("tribe_events_filter_item_tags") !== "open") {
			$("#tribe_events_filter_item_tags").addClass("closed");
		} else if(localStorage.getItem("tribe_events_filter_item_tags") === "open") {
			$("#tribe_events_filter_item_tags").removeClass("closed");
		}

		if(localStorage.getItem("tribe_events_filter_item_search") === null && localStorage.getItem("tribe_events_filter_item_search") !== "open") {
			$("#tribe_events_filter_item_search").addClass("closed");
		} else if(localStorage.getItem("tribe_events_filter_item_search") === "open") {
			$("#tribe_events_filter_item_search").removeClass("closed");
		}
	}
});

/* Module: gallery */
mod.modules.galleryOverlay = {
	currentGallery: {},
	currentGalleryID: "",
	galleryArray: [],
	galleryItems: [],
	galleryIterator: 0,
	lightboxInserted: false,
	keydownFunc: {},
	lightbox: {},
	overlayShowing: false,
	player: {},
	touchdistanceX: 0,
	touchdistanceY: 0,
	touchendX: 0,
	touchendY: 0,
	touchstartX: 0,
	touchstartY: 0,
	touchstartFunc: {},
	touchstopFunc: {},
	touchWrapper: {},
	youTubeScriptDownloaded: false,

	init: function() {
		/* jshint sub: true */
		this.galleryItems = this.galleryArray[0]["galleryItems"];
		/* jshint sub: false */
		this.currentGalleryID = this.galleryArray[0].id;
		this.touchWrapper = document.getElementById("touch");
		this.keydownFunc = this.onKeyDown.bind(this);
		this.touchstartFunc = this.onTouchStart.bind(this);
		this.touchstopFunc = this.onTouchEnd.bind(this);
		this.initClicks();
		this.initVideo();
	},

	initClicks: function() {
		$(document).on("click", ".module__gallery__content, .module__gallery__content__arrow-right, .module__gallery__content__arrow-left, .module__gallery__content__close-button, .module__gallery__nav__dot, .module__gallery__play-button, .swiper-slide", function(event) {
			event.stopPropagation();
			event.preventDefault();
			if($(this).hasClass("module__gallery__play-button")) {
				mod.modules.galleryOverlay.showOverlay();
			} else if($(this).hasClass("swiper-slide")) {
				mod.modules.galleryOverlay.showOverlay();
			} else if($(this).hasClass("module__gallery__content__close-button")) {
				mod.modules.galleryOverlay.hideOverlay();
			} else if($(this).hasClass("module__gallery__content")) {
			if(($(event.target).hasClass("module__gallery__nav__right-arrow") ||
				$(event.target).hasClass("module__gallery__nav__left-arrow") ||
				$(event.target).hasClass("module__gallery__content__arrow"))===false) {
					mod.modules.galleryOverlay.hideOverlay();
				}
			}

			if($(event.target).hasClass("module__gallery__content__arrow-right")) {
				mod.modules.galleryOverlay.scrollRight();
			}
			if($(event.target).hasClass("module__gallery__content__arrow-left")) {
				mod.modules.galleryOverlay.scrollLeft();
			}

		});
	},
	initVideo: function() {
		this.player = new YT.Player("player", {
			playerVars: {
				"autoplay": false,
				"iv_load_policy": 3,
				"modestbranding": 1,
				"rel": 0,
				"showinfo": 0
			},
			events: {
				"onReady": this.onPlayerReady.bind(this),
				"onStateChange": this.onPlayerStateChange.bind(this)
			}
		});
	},
	onPlayerReady: function() {
		this.player.cueVideoById({
			"videoId": this.galleryItems[0][0]
		});

		this.checkHash();
	},
	onPlayerStateChange: function(event) {
		if(event.data === YT.PlayerState.ENDED && this.galleryItems.length>1) {
			this.scrollRight();
		}
	},
	onKeyDown: function(event) {
		switch(event.keyCode) {
			case 39:
				//right arrow
				this.scrollRight();
				break;

			case 37:
				//left arrow
				this.scrollLeft();
				break;
		}
	},
	onTouchStart: function(event) {
		this.touchstartX = event.changedTouches[0].screenX;
		this.touchstartY = event.changedTouches[0].screenY;
	},
	onTouchEnd: function(event) {
		this.touchendX = event.changedTouches[0].screenX;
		this.touchendY = event.changedTouches[0].screenY;

		this.touchdistanceX = this.touchstartX - this.touchendX;
		this.touchdistanceY = this.touchstartY - this.touchendY;

		if(this.touchdistanceX < 0) {this.scrollLeft();} else {this.scrollRight();}
	},
	disableOverlayControls: function() {
		this.touchWrapper.removeEventListener("touchstart", this.touchstartFunc);
		this.touchWrapper.removeEventListener("touchend", this.touchendFunc);
		document.removeEventListener("keydown", this.keydownFunc);
	},
	enableOverlayControls: function() {
		this.touchWrapper.addEventListener("touchstart", this.touchstartFunc);
		this.touchWrapper.addEventListener("touchend", this.touchstopFunc);
		document.addEventListener("keydown", this.keydownFunc);
	},
	playVideo: function() {
		$("#player").css("display", "block");
		$(".module__gallery__content__image").css("display", "none");
		this.player.playVideo();
	},
	closeVideo: function() {
		this.player.stopVideo();
		this.hideOverlay();
	},
	showImage: function() {
		$("#player").css("display", "none");
		$(".module__gallery__content__image").css("display", "block").attr("src", this.galleryItems[this.galleryIterator][1].url);
	},
	checkGalleryIterator: function() {
		this.galleryIterator < 0 ? this.galleryIterator = this.galleryItems.length - 1 : null;
		this.galleryIterator > this.galleryItems.length - 1 ? this.galleryIterator = 0 : null;
	},
	hideOverlay: function() {
		$(".module__gallery__content").hide();
		this.overlayShowing = false;
		this.removeHash();
		this.player.stopVideo();
		for(var i = 0; i < this.galleryArray.length; i++) {
			this.galleryArray[i].swiper.startAutoplay();
			this.galleryArray[i].swiper.enableKeyboardControl();
		}
		this.disableOverlayControls();
	},
	showOverlay: function() {
		$(".module__gallery__content").show();
		$(".module__gallery__content").data("galleryid", this.currentGalleryID);
		this.overlayShowing = true;
		this.updateHash();
		for(var i = 0; i < this.galleryArray.length; i++) {
			this.galleryArray[i].swiper.stopAutoplay();
			this.galleryArray[i].swiper.disableKeyboardControl();
		}
		this.enableOverlayControls();

		$(".module__gallery__content__close-button").data("galleryid", this.id);
		$(".module__gallery__content__arrow-right").data("galleryid", this.id);
		$(".module__gallery__content__arrow-left").data("galleryid", this.id);

		if(this.galleryItems[this.galleryIterator][0] !== "") {
			this.playVideo();
		} else {
			this.showImage();
		}
	},
	updateHTML: function() {
		$(".module__gallery__content__sharing").find(".icon-email a").attr("href", this.galleryItems[this.galleryIterator][3][0]);
		$(".module__gallery__content__sharing").find(".icon-facebook a").attr("href", this.galleryItems[this.galleryIterator][3][1]);
		$(".module__gallery__content__sharing").find(".icon-linkedin a").attr("href", this.galleryItems[this.galleryIterator][3][2]);
		$(".module__gallery__content__sharing").find(".icon-twitter a").attr("href", this.galleryItems[this.galleryIterator][3][3]);
		$(".module__gallery__content__nav__count__iterator").html(this.galleryIterator+1);
		$(".module__gallery__content__nav__count__total").html(this.currentGallery.galleryItems.length);
	},
	checkHash: function() {
		var hash = window.location.hash.slice(1);

		/* jshint sub: true */
		for(var i = 0; i < this.galleryArray.length; i++) {
			for(var j = 0; j < this.galleryArray[i]["galleryItems"].length; j++) {
				if(hash === this.galleryArray[i]["galleryItems"][j][2][1]) {
					this.currentGalleryID = this.galleryArray[i]["id"];
					this.currentGallery = this.returnGallery(this.currentGalleryID);
					this.galleryIterator = j;
					this.showOverlay();
					this.gotoItem(this.currentGalleryID, j);
				}
			}
		}
		/* jshint sub: false */
	},
	updateHash: function() {
		window.location.hash = this.galleryItems[this.galleryIterator][2][1];
	},
	removeHash: function() {
		history.replaceState({}, document.title, ".");
	},
	scrollLeft: function() {
		this.galleryIterator--;
		this.checkGalleryIterator();
		this.gotoItem(this.currentGalleryID, this.galleryIterator);
	},
	scrollRight: function() {
		this.galleryIterator++;
		this.checkGalleryIterator();
		this.gotoItem(this.currentGalleryID, this.galleryIterator);
	},
	gotoItem: function(ID, Number) {
		this.currentGalleryID = ID;
		this.galleryIterator = Number;
		this.currentGallery = this.returnGallery(this.currentGalleryID);
		/* jshint sub: true */
		this.galleryItems = this.returnGallery(this.currentGalleryID).galleryItems;
		/* jshint sub: false */
		this.player.stopVideo();

		if(this.galleryItems[Number][0] !== "") {
			this.player.cueVideoById({
				"videoId": this.galleryItems[Number][0]
			});
		}

		if(this.overlayShowing === true) {
			if(this.galleryItems[Number][0] !== "") {
				this.playVideo();
			} else {
				this.showImage();
			}
			this.updateHash();
		}

		this.updateHTML();
	},
	returnGallery: function(ID) {
		for(var i = 0; i < this.galleryArray.length; i++) {
			if(this.galleryArray[i].id===ID) {
				return this.galleryArray[i];
			}
		}
	}
};

mod.modules.gallery = {
	id: "",
	galleryItems: {},
	swiper: {},
	swiperInit: false,
	init: function(Gallery, Id) {
		this.galleryItems = Gallery;
		this.id = Id;

		if(this.swiperInit === false) {
			this.initSwiper();
			this.swiperInit = true;
		}
	},
	initSwiper: function() {
		var spv = 3,
			spc = 2;

		if(this.galleryItems.length < 6) {
			spv = 1;
			spc = 1;
		}

		this.swiper = new Swiper (".swiper-container-" + this.id, {
			autoplay: 6000,
			keyboardControl: true,
			nextButton: ".swiper-button-next-" + this.id,
			pagination: ".swiper-pagination-" + this.id,
			paginationClickable: true,
			prevButton: ".swiper-button-prev-" + this.id,
			slidesPerView: spv,
			slidesPerColumn: spc,
			onSlideChangeStart: this.slideChangeStart.bind(this),
			onSlideChangeEnd: this.slideChangeEnd.bind(this)
		});

		if(this.overlayShowing) {
			this.swiper.stopAutoplay();
		}

		this.initSlides();
	},
	slideChangeStart: function() {
		if(this.swiper.activeIndex===this.swiper.slides.length-1) {
			$(".module__gallery__nav__right-arrow").css({"opacity": 0.1});
		} else {
			$(".module__gallery__nav__right-arrow").css({"opacity": 1});
		}

		if(this.swiper.activeIndex===0) {
			$(".module__gallery__nav__left-arrow").css({"opacity": 0.1});
		} else {
			$(".module__gallery__nav__left-arrow").css({"opacity": 1});
		}
	},
	slideChangeEnd: function() {
	},
	initSlides: function() {
		/* jshint quotmark: false */
		var playButton = '<div class="module__gallery__play-button" data-galleryid="' + this.id + '"><div class="icon icon-play-button-inside-a-circle"></div></div>';
		/* jshint quotmark: false */

		for(var i = 0; i < this.galleryItems.length; i ++) {
			var $el = $($(".swiper-slide-"+this.id)[i]);
			$el.css({"background": "url("+this.galleryItems[i][1].url+")", "background-size":"cover", "background-position": "center"});
			$el.data("slide-number", i);
			$el.data("galleryid", this.id);
			$el.on("click", function() {
				mod.modules.galleryOverlay.gotoItem($(this).data("galleryid"), $(this).data("slide-number"));
			});

			if(this.galleryItems[i][0]!=="") {
				$el.append(playButton);
			}
		}

		if( this.galleryItems.length === 1 || this.galleryItems.length === 6) {
			this.swiper.lockSwipes();
			this.swiper.paginationHide=true;
			$(".swiper-pagination-"+this.id).css({"display": "none"});
			$(".swiper-button-prev-"+this.id).css({"display": "none"});
			$(".swiper-button-next-"+this.id).css({"display": "none"});
		}
	},
};

// Used by gallery module (mod.modules.gallery).
/* jshint unused: false */
function onYouTubeIframeAPIReady() {
	mod.modules.galleryOverlay.youTubeScriptDownloaded = true;
	mod.modules.galleryOverlay.init();
}
/* jshint unused: true */


/* Module: map */
mod.modules.map = {
	bounds: {},
	buttonsActive: true,
	color: "",
	geocoder: {},
	infoWindow: {},
	isCarousel: false,
	locationsData: [],
	locIterator: 0,
	options: {},
	pin: {
		image: "",
		shadow: ""
	},
	zoom: {} || zoom,
	init: function(LocationsData, color) {
		this.locationsData = LocationsData;
		this.color = color;
		this.options = {
			fullscreenControl: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			streetViewControl: false,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_BOTTOM
			},
		};
		this.map = new google.maps.Map(document.getElementById("map"), this.options);
		this.pin.image = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + this.color.replace("#", "");
		this.pin.shadow = "https://chart.apis.google.com/chart?chst=d_map_pin_shadow";
		this.geocoder= new google.maps.Geocoder();
		this.infoWindow = new google.maps.InfoWindow();
		this.setMarkers(this.locationsData);
	},
	activateButtons: function() {
		this.buttonsActive = true;
	},
	deactivateButtons: function() {
		this.buttonsActive = false;
	},
	setMarkers: function(Locs) {
		this.bounds = new google.maps.LatLngBounds();

		for (var i = 0; i < this.locationsData.length; i++) {
			this.geocodeAddress(Locs[i].address_maps, Locs[i].name_of_location, i);
		}
	},
	geocodeAddress: function(LocMap, LocName, LocIndex) {
		this.geocoder.geocode({"address": LocMap}, function(results, status) {
			if(status === google.maps.GeocoderStatus.OK) {
				this.createMarker(results[0].geometry.location, LocName, LocIndex);
			}
		}.bind(this));
	},
	createMarker: function(LatLng, LocName, LocIndex) {
		var marker = new google.maps.Marker({
			map: this.map,
			position: LatLng,
			icon: this.pin.image,
			shadow: this.pin.shadow
		});

		this.bounds.extend(LatLng);
		this.adjustMap();
		this.markerFocus();

		google.maps.event.addListener(marker, "click", function() {
			this.locIterator = LocIndex;
			window.open(this.locationsData[LocIndex].address_link);
			// this.infoWindow.setContent(LocName);
			// this.infoWindow.open(this.map, marker);
			this.setMapHTML(LocIndex);
		}.bind(this));

		this.locationsData[LocIndex].marker = marker;
	},
	adjustMap: function() {
		this.map.fitBounds(this.bounds);
		this.map.setZoom(mod.modules.map.zoom);

		if(window.innerWidth >= mod.devices.large) {
			var mw = $("#map").width() / 3;
			this.map.panBy(mw, 0);
		}
	},
	markerFocus: function() {
		this.infoWindow.setContent(this.locationsData[this.locIterator].name_of_location);

		if(this.locationsData.length > 1) {
			this.infoWindow.open(this.map, this.locationsData[this.locIterator].marker);
		}
		this.setMapHTML(this.locIterator);
	},
	setMapHTML: function(LocIndex) {
		$(".module__map__container__info__location").html(this.locationsData[LocIndex].name_of_location);
		$(".module__map__container__info__address").html(this.locationsData[LocIndex].address_html);

		/* jshint quotmark: false */
		$(".module__map__container__info__phone").html('<a href="tel:+' + this.locationsData[LocIndex].phone_number_stripped + '">' + this.locationsData[LocIndex].phone_number + '</a>');
		$(".module__map__container__info__email").html('<a href="mailto:' + this.locationsData[LocIndex].email_address + '">' + this.locationsData[LocIndex].email_address + '</a>');
		$(".module__map__container__info__directions").html('<a href="' + this.locationsData[LocIndex].address_link + '" target="_blank"><button class="button__primary">Get Directions</button></a>');
		$(".module__map__container__nav__position").html(this.locIterator + 1);
		/* jshint quotmark: true */

		this.deactivateButtons();

		setTimeout(function() {
			this.activateButtons();
		}.bind(this), 250);
	}
};
